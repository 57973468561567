import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
	BrowserRouter,
	useLocation,
	useNavigationType,
	createRoutesFromChildren,
	matchRoutes,
} from 'react-router-dom';
import { ThemeProvider, theme } from '@fhl-world/salmon-ui';
import { SENTRY_DSN } from '@utils/common';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { isProd } from '@config/envVariables';
import AuthProvider from './components/AuthProvider';
import { handleBrowserError } from '@utils/logger/handleBrowserError';

Sentry.init({
	dsn: SENTRY_DSN,
	// environment: `prod`, // disabled because it is not showing in gitlab
	integrations: [
		new Integrations.BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes
			),
		}),
	],
	enabled: isProd,
	// release: "v1.1.1" // todo send app version
});

const root = createRoot(document.getElementById('root') as HTMLElement);

handleBrowserError();

root.render(
	<BrowserRouter>
		<ThemeProvider theme={theme}>
			<AuthProvider>
				<App />
			</AuthProvider>
		</ThemeProvider>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
