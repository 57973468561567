import { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { track } from '@amplitude/analytics-browser';

import LoanPaymentInfo from './components/LoanPaymentInfo';
import LoanNotification from './components/LoanNotification';
import ClosedAccount from './components/ClosedAccount';
import LoanOverview from './components/LoanOverview/LoanOverview';
import PaymentForm, { PaymentFormValues } from './components/PaymentForm';
import { AmplitudeEvents } from '@utils/amplitudeEvents';
import { LoanTypeToCaption } from '../MainPage/AccountList/AccountList';
import { usePostPaymentApi } from '@api/services/accountService/usePostPaymentApi';
import { useSuccessRedirect } from './utils/useSuccessRedirect';
import { AccountStatuses } from '@api/models/account';
import { useGetLoanStatus } from './utils/useGetLoanStatus';
import {
	MoneyCurrencyEnum,
	LoanResponse,
	RepaymentPaymentAccountAccountTypeEnum,
} from '../../types/generated/pos';
import { getLoanEventOptions } from './utils/eventOptions';
import { PaymentTitle } from './components/PaymentTitle';
import { Caption } from './components/Caption';
import { toPeso } from '@utils/toPeso/toPeso';

const PaymentContainer = styled.div`
	margin-top: 16px;
`;

const PlaceholderText = styled.p`
	font-size: 15px;
	line-height: 24px;
	font-weight: 500;
	font-style: normal;
	margin: 0 0 16px;
`;

interface Props {
	loan: LoanResponse;
}

function LoanAccount(props: Props) {
	const { loan } = props;

	const eventOptions = getLoanEventOptions(loan);

	const loanStatus = useGetLoanStatus(loan);

	const [{ data: paymentData, error: paymentError, loading }, mutate] =
		usePostPaymentApi();

	const initialValues: Partial<PaymentFormValues> = useMemo(() => {
		return {
			amount: loan?.nextPayment ? toPeso(loan?.nextPayment.amount) : undefined,
			providerId: undefined,
		};
	}, [loan?.nextPayment]);

	const handleSubmit = useCallback(
		({ amount, providerId }: PaymentFormValues) => {
			if (!providerId) {
				return;
			}

			track(AmplitudeEvents.GoToPayment_Tap, { product: eventOptions });

			mutate({
				providerId,
				amount: { amount, currency: MoneyCurrencyEnum.PHP },
				from: {
					accountId: String(loan.loanId),
					accountType: RepaymentPaymentAccountAccountTypeEnum.FINANCING,
				},
			});
		},
		[track, eventOptions]
	);

	useSuccessRedirect(paymentData?.location);

	if (loan.status === AccountStatuses.CLOSED) {
		return <ClosedAccount loanType={loan.loanType} />;
	}

	return (
		<div>
			<Caption>{LoanTypeToCaption[loan.loanType] || ''}</Caption>

			<LoanPaymentInfo
				notificationState={loanStatus}
				nextPayment={loan.nextPayment}
			/>

			<LoanNotification loan={loan} notificationState={loanStatus} />

			<PaymentContainer>
				<PaymentTitle>Payment</PaymentTitle>
				<PlaceholderText>
					Enter an amount and select a payment option
				</PlaceholderText>
			</PaymentContainer>

			<PaymentForm
				initialValues={initialValues}
				onSubmit={handleSubmit}
				eventOptions={eventOptions}
				serverError={paymentError}
				loading={loading}
			/>

			<LoanOverview loan={loan} />
		</div>
	);
}

export default LoanAccount;
