export enum PaymentNotificationStates {
	Congratulations = 'Congratulations',
	NDaysOrLess_BeforeDueDate_NotPaid = 'NDaysOrLess_BeforeDueDate_NotPaid',
	NDaysOrLess_BeforeDueDate_PartialPaid = 'NDaysOrLess_BeforeDueDate_PartialPaid',
	Today_DueDate_NotPaid = 'Today_DueDate_NotPaid',
	Today_DueDate_PartialPaid = 'Today_DueDate_PartialPaid',
	PartialPayment_MoreThan7daysBeforeDueDate = 'PartialPayment_MoreThan7daysBeforeDueDate',
	NoMissedPayments_MoreThan7daysBeforeDueDate = 'NoMissedPayments_MoreThan7daysBeforeDueDate',
	PaidFull_BeforeDueDate = 'PaidFull_BeforeDueDate',
	PaidMore_BeforeDueDate = 'PaidMore_BeforeDueDate',
	MissedPayment_NoFee = 'MissedPayment_NoFee',
	MissedPayment_Fee = 'MissedPayment_Fee',
	LoanClosed = 'LoanClosed',
	NDaysOrLess_BeforeDueDate_NeedToPayFeeAndMissedPayment = 'NDaysOrLess_BeforeDueDate_NeedToPayFeeAndMissedPayment',
	NeedToPayFee_MoreThan7daysBeforeDueDate = 'NeedToPayFee_MoreThan7daysBeforeDueDate',
	NDaysOrLess_BeforeDueDate_NeedToPayFee_NotPaid = 'NDaysOrLess_BeforeDueDate_NeedToPayFee_NotPaid',
}
