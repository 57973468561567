import styled from '@emotion/styled';
import { format } from 'date-fns';

import { PaymentCaptionByPaymentState } from '../utils/paymentCaptions';
import { PaymentNotificationStates } from '@api/models/notificationStates';
import { parseDate } from '@utils/date';
import { LoanNextPayment } from '../../../types/generated/pos';
import { formatSentimo } from '@utils/formatSentimo/formatSentimo';

const InnerContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
`;

const PaymentStatusText = styled.span`
	font-size: 16px;
	line-height: 28px;
	font-weight: 800;
	font-style: normal;
	margin-bottom: 2px;
`;

const PaymentAmount = styled.h3`
	font-size: 28px;
	line-height: 32px;
	font-weight: 800;
	margin: 0;
	padding: 0;
`;

const PaymentDateContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 56px;
	height: 56px;
	border-radius: 16px;
	background-color: #f6f6f6;
`;

const Month = styled.span`
	font-size: 15px;
	line-height: 24px;
	text-transform: uppercase;
	color: #ea5347;
`;

const Day = styled.span`
	font-size: 24px;
	line-height: 28px;
	font-weight: 800;
`;

type LoanPaymentInfoProps = {
	notificationState: PaymentNotificationStates | null;
	nextPayment?: LoanNextPayment;
};

const LoanPaymentInfo = (props: LoanPaymentInfoProps) => {
	const { notificationState, nextPayment } = props;

	const parsedDueDate = nextPayment && parseDate(nextPayment.dueDate);

	return (
		<InnerContainer>
			<div>
				<PaymentStatusText>
					{notificationState && PaymentCaptionByPaymentState[notificationState]}
				</PaymentStatusText>
				<PaymentAmount>{formatSentimo(nextPayment?.amount || 0)}</PaymentAmount>
			</div>
			<PaymentDateContainer>
				<Month>{parsedDueDate && format(parsedDueDate, 'MMM')}</Month>
				<Day>{parsedDueDate && format(parsedDueDate, 'd')}</Day>
			</PaymentDateContainer>
		</InnerContainer>
	);
};

export default LoanPaymentInfo;
