import { useState, useEffect } from 'react';
import { differenceInCalendarDays, differenceInMonths } from 'date-fns';
import { track } from '@amplitude/analytics-browser';

import { AccountStatuses, LoanTypes } from '@api/models/account';
import { PaymentNotificationStates } from '@api/models/notificationStates';
import { AmplitudeEvents } from '@utils/amplitudeEvents';
import { parseDate } from '@utils/date';
import {
	LoanResponse,
	OperationStatusEnum,
} from '../../../types/generated/pos';
import { useGetOperationsApi } from '@api/services/accountService/useGetOperationsApi';
import { getLoanEventOptions } from './eventOptions';

const WarningPeriodDays = 7;

const LoanTypeToEvent = {
	[LoanTypes.POS]: AmplitudeEvents.POSMain_Opened,
	[LoanTypes.CASH]: AmplitudeEvents.CashloanMain_Opened,
};

export const useGetLoanStatus = (
	loan: LoanResponse
): PaymentNotificationStates | null => {
	const [notificationState, setNotificationState] =
		useState<PaymentNotificationStates | null>(null);

	const operationsState = useGetOperationsApi({
		accountId: loan.loanId,
		type: 'FINANCING',
	});

	useEffect(() => {
		if (loan) {
			const eventOptions = getLoanEventOptions(loan);

			const amplitudeEvent = LoanTypeToEvent[loan.loanType];

			if (loan.status === AccountStatuses.CLOSED) {
				track(amplitudeEvent, {
					state: PaymentNotificationStates.LoanClosed,
					product: eventOptions,
				});
				setNotificationState(PaymentNotificationStates.LoanClosed);
				return;
			} else if (loan) {
				const { nextPayment, progress } = loan;
				const parsedDueDate = loan?.nextPayment?.dueDate
					? parseDate(loan?.nextPayment?.dueDate)
					: null;

				const parsedUpcomingDueDate = nextPayment?.upcoming?.dueDate
					? parseDate(nextPayment.upcoming.dueDate)
					: null;

				const daysBeforeDueDate =
					parsedDueDate && differenceInCalendarDays(parsedDueDate, new Date());

				const upcomingDaysBeforeDueDate =
					parsedUpcomingDueDate &&
					differenceInCalendarDays(parsedUpcomingDueDate, new Date());

				const lastPaid = operationsState.data?.operations.find((operation) => {
					return operation.status === OperationStatusEnum.SUCCESSFUL;
				});

				if (loan.status === AccountStatuses.OVERDUE) {
					if (
						upcomingDaysBeforeDueDate !== null &&
						upcomingDaysBeforeDueDate <= WarningPeriodDays &&
						nextPayment !== undefined &&
						nextPayment?.debt > 0 &&
						nextPayment?.fee > 0
					) {
						setNotificationState(
							PaymentNotificationStates.NDaysOrLess_BeforeDueDate_NeedToPayFeeAndMissedPayment
						);
						track(amplitudeEvent, {
							state:
								PaymentNotificationStates.NDaysOrLess_BeforeDueDate_NeedToPayFeeAndMissedPayment,
							product: eventOptions,
						});
						return;
					}

					if (
						nextPayment?.upcoming !== null &&
						nextPayment !== undefined &&
						nextPayment?.fee > 0 &&
						nextPayment?.amount === nextPayment?.fee
					) {
						setNotificationState(
							PaymentNotificationStates.NDaysOrLess_BeforeDueDate_NeedToPayFee_NotPaid
						);
						track(amplitudeEvent, {
							state:
								PaymentNotificationStates.NDaysOrLess_BeforeDueDate_NeedToPayFee_NotPaid,
							product: eventOptions,
						});
						return;
					}

					if (
						upcomingDaysBeforeDueDate === null &&
						nextPayment?.debt === 0 &&
						nextPayment?.fee > 0
					) {
						setNotificationState(
							PaymentNotificationStates.NeedToPayFee_MoreThan7daysBeforeDueDate
						);
						track(amplitudeEvent, {
							state:
								PaymentNotificationStates.NeedToPayFee_MoreThan7daysBeforeDueDate,
							product: eventOptions,
						});
						return;
					}

					// has fee
					if (nextPayment !== undefined && nextPayment?.fee > 0) {
						setNotificationState(PaymentNotificationStates.MissedPayment_Fee);
						track(amplitudeEvent, {
							state: PaymentNotificationStates.MissedPayment_Fee,
							product: eventOptions,
						});
						return;
						// no fee
					} else {
						setNotificationState(PaymentNotificationStates.MissedPayment_NoFee);
						track(amplitudeEvent, {
							state: PaymentNotificationStates.MissedPayment_NoFee,
							product: eventOptions,
						});
						return;
					}
				}

				const differenceMonthsBetweenDueDateAndToday = parsedDueDate
					? differenceInMonths(parsedDueDate, new Date())
					: null;
				if (
					daysBeforeDueDate &&
					daysBeforeDueDate > WarningPeriodDays &&
					differenceMonthsBetweenDueDateAndToday !== null &&
					differenceMonthsBetweenDueDateAndToday > 0
				) {
					if (nextPayment?.paid !== undefined && nextPayment?.paid > 0) {
						setNotificationState(
							PaymentNotificationStates.PaidMore_BeforeDueDate
						);
						track(amplitudeEvent, {
							state: PaymentNotificationStates.PaidMore_BeforeDueDate,
							product: eventOptions,
						});
						return;
					}
				}

				if (
					differenceMonthsBetweenDueDateAndToday !== null &&
					differenceMonthsBetweenDueDateAndToday > 0
				) {
					setNotificationState(
						PaymentNotificationStates.PaidFull_BeforeDueDate
					);
					track(amplitudeEvent, {
						state: PaymentNotificationStates.PaidFull_BeforeDueDate,
						product: eventOptions,
					});
					return;
				}

				// partial paid before WarningPeriodDays days
				if (
					daysBeforeDueDate &&
					daysBeforeDueDate > WarningPeriodDays &&
					nextPayment?.paid !== 0
				) {
					setNotificationState(
						PaymentNotificationStates.PartialPayment_MoreThan7daysBeforeDueDate
					);
					track(amplitudeEvent, {
						state:
							PaymentNotificationStates.PartialPayment_MoreThan7daysBeforeDueDate,
						product: eventOptions,
					});
					return;
				}

				// first notification
				if (
					// check not null
					daysBeforeDueDate &&
					// check days before due date more than warning period
					daysBeforeDueDate > WarningPeriodDays &&
					// check not paid
					progress.paid === 0 &&
					!lastPaid
				) {
					setNotificationState(PaymentNotificationStates.Congratulations);
					track(amplitudeEvent, {
						state: PaymentNotificationStates.Congratulations,
						product: eventOptions,
					});
					return;
				}

				if (
					// check not null
					daysBeforeDueDate &&
					// check days before due date more than warning period
					daysBeforeDueDate > WarningPeriodDays
				) {
					setNotificationState(
						PaymentNotificationStates.NoMissedPayments_MoreThan7daysBeforeDueDate
					);
					track(amplitudeEvent, {
						state:
							PaymentNotificationStates.NoMissedPayments_MoreThan7daysBeforeDueDate,
						product: eventOptions,
					});
					return;
				}

				//Today is due date + partial paid
				if (daysBeforeDueDate === 0) {
					if (nextPayment?.paid !== 0) {
						setNotificationState(
							PaymentNotificationStates.Today_DueDate_PartialPaid
						);
						track(amplitudeEvent, {
							state: PaymentNotificationStates.Today_DueDate_PartialPaid,
							product: eventOptions,
						});
						return;
						// Today is due date
					} else {
						setNotificationState(
							PaymentNotificationStates.Today_DueDate_NotPaid
						);
						track(amplitudeEvent, {
							state: PaymentNotificationStates.Today_DueDate_NotPaid,
							product: eventOptions,
						});
						return;
					}
				}

				// WarningPeriodDays or fewer days before the due date
				if (daysBeforeDueDate && daysBeforeDueDate <= WarningPeriodDays) {
					if (nextPayment?.paid !== 0) {
						setNotificationState(
							PaymentNotificationStates.NDaysOrLess_BeforeDueDate_PartialPaid
						);
						track(amplitudeEvent, {
							state:
								PaymentNotificationStates.NDaysOrLess_BeforeDueDate_PartialPaid,
							product: eventOptions,
						});
						return;
					} else {
						setNotificationState(
							PaymentNotificationStates.NDaysOrLess_BeforeDueDate_NotPaid
						);
						track(amplitudeEvent, {
							state:
								PaymentNotificationStates.NDaysOrLess_BeforeDueDate_NotPaid,
							product: eventOptions,
						});
						return;
					}
				}
			}
			// if we have no loan but have loan list send event
		}
	}, [loan]);

	return notificationState;
};
