import { PaymentNotificationStates } from '@api/models/notificationStates';

export enum PaymentCaption {
	UPCOMING = 'Upcoming payment',
	NEXT_PAYMENT = 'Next payment',
	TODAYS_PAYMENT = "Today's payment",
	MISSED_PAYMENT = 'Missed payment',
	URGENT_PAYMENT = 'Urgent! You need to pay',
	CLOSED = 'Financial product closed', // technical field, not used in UI
}

export const PaymentCaptionByPaymentState: {
	[key in PaymentNotificationStates]: PaymentCaption;
} = {
	[PaymentNotificationStates.Congratulations]: PaymentCaption.UPCOMING,
	[PaymentNotificationStates.NDaysOrLess_BeforeDueDate_NotPaid]:
		PaymentCaption.UPCOMING,
	[PaymentNotificationStates.NDaysOrLess_BeforeDueDate_PartialPaid]:
		PaymentCaption.UPCOMING,
	[PaymentNotificationStates.Today_DueDate_NotPaid]:
		PaymentCaption.TODAYS_PAYMENT,
	[PaymentNotificationStates.Today_DueDate_PartialPaid]:
		PaymentCaption.TODAYS_PAYMENT,
	[PaymentNotificationStates.PartialPayment_MoreThan7daysBeforeDueDate]:
		PaymentCaption.UPCOMING,
	[PaymentNotificationStates.NoMissedPayments_MoreThan7daysBeforeDueDate]:
		PaymentCaption.UPCOMING,
	[PaymentNotificationStates.PaidFull_BeforeDueDate]:
		PaymentCaption.NEXT_PAYMENT,
	[PaymentNotificationStates.PaidMore_BeforeDueDate]:
		PaymentCaption.NEXT_PAYMENT,
	[PaymentNotificationStates.MissedPayment_NoFee]:
		PaymentCaption.MISSED_PAYMENT,
	[PaymentNotificationStates.MissedPayment_Fee]: PaymentCaption.URGENT_PAYMENT,
	[PaymentNotificationStates.NDaysOrLess_BeforeDueDate_NeedToPayFeeAndMissedPayment]:
		PaymentCaption.URGENT_PAYMENT,
	[PaymentNotificationStates.NeedToPayFee_MoreThan7daysBeforeDueDate]:
		PaymentCaption.URGENT_PAYMENT,
	[PaymentNotificationStates.NDaysOrLess_BeforeDueDate_NeedToPayFee_NotPaid]:
		PaymentCaption.URGENT_PAYMENT,
	[PaymentNotificationStates.LoanClosed]: PaymentCaption.CLOSED, // not used
};
