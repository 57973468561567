import {
	AccountsResponse,
	LoanResponseLoanTypeEnum,
	LoanResponseStatusEnum,
} from '../../types/generated/pos';

export type ApiAccountData = AccountsResponse;
export type ApiLoanData = AccountsResponse['loans'][number];
export const LoanTypes = LoanResponseLoanTypeEnum;
export const AccountStatuses = LoanResponseStatusEnum;

export interface UpcomingPaymentData {
	amount: number;
	dueDate: string;
}

export interface NextPaymentData {
	base: number;
	amount: number;
	debt: number;
	dueDate: string;
	fee: number;
	paid: number;
	upcoming?: UpcomingPaymentData;
}
