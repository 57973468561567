import { differenceInCalendarDays } from 'date-fns';

import Notification from './Notification/Notification';
import { parseDate } from '@utils/date';
import { PaymentNotificationStates } from '@api/models/notificationStates';
import { PermittedNotificationTypes } from './Notification/config';
import { LoanResponse } from '../../../types/generated/pos';
import { formatSentimo } from '@utils/formatSentimo/formatSentimo';
import { getDateWithYear } from '@utils/dates/formatDates';

interface PaymentNotificationProps {
	loan: LoanResponse;
	notificationState: PaymentNotificationStates | null;
}

function LoanNotification(props: PaymentNotificationProps) {
	const { notificationState, loan } = props;
	const { nextPayment, progress } = loan;

	const parsedDueDate = nextPayment?.dueDate
		? parseDate(nextPayment.dueDate)
		: null;

	const parsedUpcomintDueDate = nextPayment?.upcoming?.dueDate
		? parseDate(nextPayment.upcoming.dueDate)
		: null;

	const daysBeforeDueDate =
		parsedDueDate !== null &&
		differenceInCalendarDays(parsedDueDate, new Date());

	if (props.loan && notificationState) {
		switch (notificationState) {
			case PaymentNotificationStates.MissedPayment_Fee:
				return (
					<Notification
						type={PermittedNotificationTypes.BAD_NOTIFICATION}
						msgCmp={
							<div>
								You missed {progress.missed} payments. Remains payment is{' '}
								{formatSentimo(nextPayment?.debt || 0)}, plus included a late
								fee of {formatSentimo(nextPayment?.fee || 0)}. Pay now to avoid
								more late fees
							</div>
						}
					/>
				);
			case PaymentNotificationStates.MissedPayment_NoFee:
				return (
					<Notification
						type={PermittedNotificationTypes.BAD_NOTIFICATION}
						msgCmp={
							<div>
								You missed {progress.missed} payment. Pay now to avoid late fees
							</div>
						}
					/>
				);
			case PaymentNotificationStates.PaidMore_BeforeDueDate:
				return (
					<Notification
						type={PermittedNotificationTypes.SUCCESS_NOTIFICATION}
						msgCmp={
							<div>
								Payment received. You overpaid{' '}
								{formatSentimo(nextPayment?.paid || 0)}, which will be applied
								to your next payment. The next payment will be{' '}
								{formatSentimo(nextPayment?.amount || 0)} due on{' '}
								{parsedDueDate && getDateWithYear(parsedDueDate)}
							</div>
						}
					/>
				);
			case PaymentNotificationStates.Congratulations:
				return (
					<Notification
						type={PermittedNotificationTypes.SUCCESS_NOTIFICATION}
						msgCmp={
							"Congratulations on your new purchase! You'll be notified about the first payment 7 days before"
						}
					/>
				);
			case PaymentNotificationStates.NoMissedPayments_MoreThan7daysBeforeDueDate:
				return (
					<Notification
						type={PermittedNotificationTypes.SUCCESS_NOTIFICATION}
						msgCmp={
							"No missed payments. You'll be notified about upcoming payment 7 days before"
						}
					/>
				);
			case PaymentNotificationStates.PartialPayment_MoreThan7daysBeforeDueDate:
				return (
					<Notification
						type={PermittedNotificationTypes.SUCCESS_NOTIFICATION}
						msgCmp={`You made a partial payment of ${formatSentimo(
							nextPayment?.paid || 0
						)} out of ${formatSentimo(nextPayment?.base || 0)} `}
					/>
				);
			case PaymentNotificationStates.Today_DueDate_PartialPaid:
				return (
					<Notification
						type={PermittedNotificationTypes.WARNING_NOTIFICATION}
						msgCmp={
							<div>
								Today is your due date. You paid{' '}
								{formatSentimo(nextPayment?.paid || 0)} out of{' '}
								{formatSentimo(nextPayment?.base || 0)}. Don’t forget to pay in
								full to avoid late fees
							</div>
						}
					/>
				);
			case PaymentNotificationStates.Today_DueDate_NotPaid:
				return (
					<Notification
						type={PermittedNotificationTypes.WARNING_NOTIFICATION}
						msgCmp={
							<div>
								<div>Today is your due date.</div>
								<div>Don’t forget to pay in full to avoid late fees</div>
							</div>
						}
					/>
				);
			case PaymentNotificationStates.NDaysOrLess_BeforeDueDate_PartialPaid:
				return (
					<Notification
						type={PermittedNotificationTypes.WARNING_NOTIFICATION}
						msgCmp={
							<div>
								{daysBeforeDueDate} days to the due date. You made a partial
								payment of {formatSentimo(nextPayment?.paid || 0)} out of{' '}
								{formatSentimo(nextPayment?.base || 0)}. Don’t forget to pay in
								full to avoid late fees
							</div>
						}
					/>
				);
			case PaymentNotificationStates.NDaysOrLess_BeforeDueDate_NotPaid:
				return (
					<Notification
						type={PermittedNotificationTypes.WARNING_NOTIFICATION}
						msgCmp={
							<div>
								{daysBeforeDueDate} days to the due date. Don’t forget to pay to
								avoid late fees
							</div>
						}
					/>
				);
			case PaymentNotificationStates.PaidFull_BeforeDueDate:
				return (
					<Notification
						type={PermittedNotificationTypes.SUCCESS_NOTIFICATION}
						msgCmp={
							<div>
								Payment received. The next payment will be{' '}
								{formatSentimo(nextPayment?.amount || 0)} due on{' '}
								{parsedDueDate && getDateWithYear(parsedDueDate)}
							</div>
						}
					/>
				);
			case PaymentNotificationStates.NDaysOrLess_BeforeDueDate_NeedToPayFeeAndMissedPayment:
				return (
					<Notification
						type={PermittedNotificationTypes.BAD_NOTIFICATION}
						msgCmp={
							<div>
								You missed {progress.missed} payment. You need to pay{' '}
								{formatSentimo(nextPayment?.debt || 0)}, plus a late fee of{' '}
								{formatSentimo(nextPayment?.fee || 0)}. Reminding you that the
								next payment of{' '}
								{formatSentimo(nextPayment?.upcoming?.amount || 0)} is still due
								on{' '}
								{parsedUpcomintDueDate &&
									getDateWithYear(parsedUpcomintDueDate)}
								. Pay now to avoid more late fees
							</div>
						}
					/>
				);
			case PaymentNotificationStates.NeedToPayFee_MoreThan7daysBeforeDueDate:
				return (
					<Notification
						type={PermittedNotificationTypes.BAD_NOTIFICATION}
						msgCmp={
							<div>
								You still need to pay a late fee of{' '}
								{formatSentimo(nextPayment?.fee || 0)}. Pay now to avoid more
								late fees
							</div>
						}
					/>
				);
			case PaymentNotificationStates.NDaysOrLess_BeforeDueDate_NeedToPayFee_NotPaid:
				return (
					<Notification
						type={PermittedNotificationTypes.BAD_NOTIFICATION}
						msgCmp={
							<div>
								You still need to pay a late fee of{' '}
								{formatSentimo(nextPayment?.fee || 0)}. Reminding you that the
								next payment of{' '}
								{formatSentimo(nextPayment?.upcoming?.amount || 0)} is still due
								on{' '}
								{parsedUpcomintDueDate &&
									getDateWithYear(parsedUpcomintDueDate)}
								. Pay now to avoid more late fees
							</div>
						}
					/>
				);
			default:
				return null;
		}
	}

	return <div></div>;
}

export default LoanNotification;
